<template>
  <div>
    <KTCodePreview v-bind:title="'Nhập sản phẩm'">
      <template v-slot:preview>
        <b-form ref="form" class="row">
          <!-- Input session -->
          <b-container class="bv-example-row">
            <b-row>
              <b-col>
                <div class="form-group">
                  <span>
                    <b>Bước 1:</b> Vui lòng chuẩn bị dữ liệu mẫu từ file excel
                    đúng format, hoặc có thể tải ở
                    <a :href="urlExcel">đây</a>
                  </span>
                </div>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <span> <b>Bước 2:</b> Tiến hành Import </span>
                <div style="padding-top: 15px">
                  <b-form-file
                    placeholder="Hãy chọn một tập tin"
                    @change="selectFile"
                    v-model="file"
                    ref="file-input"
                    class="mb-2"
                  ></b-form-file>
                </div>
                <b-progress :value="progress" :max="100" show-progress>
                  <b-progress-bar
                    :value="progress"
                    :label="`${progress.toFixed(2)}%`"
                  ></b-progress-bar>
                </b-progress>
              </b-col>
            </b-row>
            <b-row class="mt-4">
              <b-col md="3">
                <b-form-group>
                  <label><b>Loại:</b></label>
                  <b-form-select
                    class="select-style"
                    v-model="selectedType"
                    :options="listType"
                    size="sm"
                    value-field="id"
                    text-field="name"
                  >
                  </b-form-select>
                </b-form-group>
              </b-col>
            </b-row>
          </b-container>
          <!-- End input session -->

          <b-container class="mt-10" v-show="importItems.length > 0">
            <h5>Tổng số sản phẩm từ file excel: {{ importItems.length }}</h5>
            <b-table
              v-if="false"
              class="myTable"
              responsive
              bordered
              hover
              :fields="fields"
              :items="importItems"
            >
              <template v-slot:cell(originalPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.originalPrice)"></span>
                </div>
              </template>

              <template v-slot:cell(sellingPrice)="row">
                <div style="text-align: end">
                  <span v-text="convertPrice(row.item.sellingPrice)"></span>
                </div>
              </template>

              <template v-slot:cell(weight)="row">
                <div style="text-align: end">
                  <span v-text="row.item.weight"></span>
                </div>
              </template>

              <template v-slot:cell(length)="row">
                <div style="text-align: end">
                  <span v-text="row.item.length"></span>
                </div>
              </template>

              <!-- <template v-slot:cell(length)="row">
                    <div style="text-align: end;">
                      <span v-text="row.item.length"></span>
                    </div>
                  </template>-->

              <template v-slot:cell(height)="row">
                <div style="text-align: end">
                  <span v-text="row.item.height"></span>
                </div>
              </template>
            </b-table>
          </b-container>
        </b-form>
      </template>
      <template v-slot:foot>
        <div class="bv-example-row container">
          <b-row>
            <b-button
              style="fontweight: 600; width: 70px"
              variant="primary"
              size="sm"
              @click="importProduct"
              >Lưu</b-button
            >
            <router-link to="/products" tag="button">
              <b-button
                style="margin-left: 10px; font-weight: 600; width: 70px"
                variant="secondary"
                size="sm"
                >Hủy</b-button
              >
            </router-link>
          </b-row>
        </div>
      </template>
    </KTCodePreview>
  </div>
</template>
<style scoped>
.table th,
.table td {
  text-align: center;
}

.myTable /deep/ .table {
  width: max-content !important;
  color: #464e5f;
  background-color: transparent;
}
</style>

<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/breadcrumbs.module';
import xlsx from 'xlsx';
import axios from 'axios';
import { URL_IMPORT_PRODUCT } from '../../../utils/constants';

export default {
  data() {
    return {
      fields: [
        {
          key: 'productName',
          label: 'Tên sản phẩm',
        },
        {
          key: 'productCode',
          label: 'Mã sản phẩm',
        },
        {
          key: 'barCode',
          label: 'Mã vạch',
        },
        {
          key: 'parentProduct',
          label: 'Sản phẩm cha',
        },
        {
          key: 'productCategory',
          label: 'Danh mục',
        },
        {
          key: 'originalPrice',
          label: 'Giá nhập',
        },
        {
          key: 'sellingPrice',
          label: 'Giá bán',
        },
        {
          key: 'productType',
          label: 'Loại sản phẩm',
        },
        {
          key: 'original',
          label: 'Xuất xứ',
        },
        {
          key: 'brand',
          label: 'Thương hiệu',
        },
        {
          key: 'color',
          label: 'Màu sắc',
        },
        {
          key: 'size',
          label: 'Tình trạng',
        },
        {
          key: 'weight',
          label: 'Khối lượng',
        },
        {
          key: 'length',
          label: 'Chiều dài',
        },
        {
          key: 'width',
          label: 'Chiều rộng',
        },
        {
          key: 'height',
          label: 'Chiều cao',
        },
        {
          key: 'warrantyAddress',
          label: 'Địa chỉ bảo hành',
        },
        {
          key: 'warrantyPhone',
          label: 'Số điện thoại',
        },
        {
          key: 'warrantyMonthNo',
          label: 'Số tháng bảo hành',
        },
        {
          key: 'warrantyDescription',
          label: 'Nội dung bảo hành',
        },
      ],
      importItems: [],
      file: null,
      excellist: [],
      progress: 0,
      uploading: false,
      urlExcel: URL_IMPORT_PRODUCT,
      selectedType: 1,
      listType: [
        {
          id: 1,
          name: 'Tạo mới',
        },
        {
          id: 2,
          name: 'Cập nhật',
        },
      ],
    };
  },
  components: {
    KTCodePreview,
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: 'Sản phẩm', route: '/products' },
      { title: 'Danh sách sản phẩm', route: '/products' },
      { title: 'Nhập sản phẩm' },
    ]);
  },
  methods: {
    convertPrice: function (number) {
      return new Intl.NumberFormat('vn-VN').format(number);
    },

    importProduct: async function () {
      this.uploading = true;
      if (!this.file) {
        return;
      }

      var config = {
        onUploadProgress: (progressEvent) => {
          this.progress = Math.round(
            (progressEvent.loaded * 100) / progressEvent.total
          );
        },
      };

      const data = {
        listProduct: this.excellist,
        type: this.selectedType,
      };

      axios
        .post('product/import-product', data, config)
        .then((response) => {
          const { status, message } = response.data;
          console.log('Respone: ', response.data);
          if (status === 1) {
            this.clearFiles();
            this.$router.push({
              name: 'list-products',
              params: { importProductSuccess: true, message: message },
            });
          } else {
            this.makeToastFaile(message);
          }
          this.uploading = false;
        })
        .catch((e) => {
          console.log('Catch: ', e);
          this.makeToastFaile(e);
          this.uploading = false;
        });
    },
    selectFile(event) {
      const files = event.target.files[0];
      this.file = files;
      if (!files) {
        return;
      } else if (!/\.(xls|xlsx)$/.test(files.name.toLowerCase())) {
        this.file = null;
        this.clearFiles();
        return alert(
          'Định dạng của file tải lên không chính xác. Vui lòng tải lên file có đuôi .xls hoặc .xlsx'
        );
      }
      const fileReader = new FileReader();
      fileReader.onload = (ev) => {
        try {
          const data = ev.target.result;
          const XLSX = xlsx;
          const workbook = XLSX.read(data, {
            type: 'binary',
          });
          const wsname = workbook.SheetNames[0]; // Take the first sheet，wb.SheetNames[0] :Take the name of the first sheet in the sheets
          const ws = XLSX.utils.sheet_to_json(workbook.Sheets[wsname], {
            blankRows: false,
          }); // Generate JSON table content，wb.Sheets Get the data of the first sheet
          const excellist = []; // Clear received data
          // Edit data
          for (var i = 0; i < ws.length; i++) {
            excellist.push(ws[i]);
          } // At this point, you get an array containing objects that need to be processed
          this.excellist = excellist;
          this.excellist.map((element) => {
            let data = {
              productName: element['Tên sản phẩm'],
              productCode: element['Mã sản phẩm'],
              productNameVat: element['Tên VAT'],
              barCode: element['Mã vạch'],
              parentProduct: element['Sản phẩm cha'],
              productCategory: element['Danh mục'],
              productCategoryInternal: element['Danh mục nội bộ'],
              originalPrice: element['Giá nhập'],
              wholesalePrice: element['Giá bán buôn'],
              listedPrice: element['Giá niêm yết'],
              sellingPrice: element['Giá bán'],
              productType: element['Loại sản phẩm'],
              original: element['Xuất xứ'],
              brand: element['Thương hiệu'],
              color: element['Màu sắc'],
              size: element['Tình trạng'],
              weight: element['Khối lượng'],
              length: element['Chiều dài'],
              width: element['Chiều rộng'],
              height: element['Chiều cao'],
              warrantyAddress: element['Địa chỉ bảo hành'],
              warrantyPhone: element['Số điện thoại'],
              warrantyMonthNo: element['Số tháng bảo hành'],
              warrantyDescription: element['Nội dung bảo hành'],
            };
            this.importItems.push(data);
          });
        } catch (e) {
          return alert('Read failure!');
        }
      };
      fileReader.readAsBinaryString(files);
    },
    clearFiles() {
      this.$refs['file-input'].reset();
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
